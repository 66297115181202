<template>
  <v-row mb-3>
    <v-col class="mx-auto">
      <v-text-field
        :label="this.inputPlaceholderText"
        variant="filled"
        clearable
        @keyup="handleSearch($event.target.value)"
        @click:clear="handleClear($event.target.value)"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    props: {
      inputPlaceholderText: String
    },
    methods: {
      handleSearch (event) {
        this.$emit("search", event)
      },
      handleClear() {
        this.$emit("clear")
        this.handleSearch("");
      }
    }
  }
</script>
