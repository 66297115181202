import { createRouter, createWebHistory } from 'vue-router'
import checkStep1 from './middleware/checkStep1'
import checkStep2 from './middleware/checkStep2'
import checkStep3 from './middleware/checkStep3'
import checkStep4 from './middleware/checkStep4'
import checkStep5 from './middleware/checkStep5'
import checkStep6 from './middleware/checkStep6'
import checkCurrentBrand from './middleware/checkCurrentBrand'
import checkCustomerInfoAttrs from './middleware/checkCustomerInfoAttrs'
import Home from '../pages/Home.vue'
import Step1WhoAreYou from '../pages/Step1WhoAreYou.vue'
import Step2WhoIsThisFor from '../pages/Step2WhoIsThisFor.vue'
import Step2aByBrand from '../pages/Step2aByBrand.vue'
import Step2aByApplication from '../pages/Step2aByApplication.vue'
import Step3SaleAlreadyHappened from '../pages/Step3SaleAlreadyHappened.vue'
import Step4WillThisBeSold from '../pages/Step4WillThisBeSold.vue'
import Step5CustomerInfo from '../pages/Step5CustomerInfo.vue'
import Step6LetsConfirm from '../pages/Step6LetsConfirm.vue'
import Step7AlmostDone from '../pages/Step7AlmostDone.vue'
import BonusSchedule from '../pages/BonusSchedule.vue'
import TrainingGuide from '../pages/TrainingGuide.vue'
import FAQs from '../pages/FAQs.vue'
import GMFAQs from '../pages/faqs/GMFAQs.vue'
import ProMachSolutions from '../pages/ProMachSolutions.vue'
import PageNotFound from '../pages/PageNotFound.vue'
import Maintenance from '../pages/Maintenance.vue'
import { useStore } from '../store/store'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    // STEP 1
    {
      path: '/who-are-you/',
      name: 'who-are-you',
      component: Step1WhoAreYou,
      pathToRegexpOptions: { strict: true },
      meta: {
        title: 'Who are you?'
      }
    },
        // STEP 2
        {
          path: '/who-is-this-for/',
          name: 'who-is-this-for',
          component: Step2WhoIsThisFor,
          pathToRegexpOptions: { strict: true },
          meta: {
            title: 'Who is this for?',
            middleware: [
              checkStep1
            ]
          }
        },
        // STEP 2A
        {
          path: '/by-brand/',
          name: 'by-brand',
          component: Step2aByBrand,
          pathToRegexpOptions: { strict: true },
          meta: {
            title: 'Select by Brand',
            middleware: [
              checkStep1
            ]
          }
        }, 
        // STEP 2A
        {
          path: '/by-application/',
          name: 'by-application',
          component: Step2aByApplication,
          pathToRegexpOptions: { strict: true },
          meta: {
            title: 'Select by Application',
            middleware: [
              checkStep1
            ]
          }
        },
        // STEP 3
        {
          path: '/sale-already-happened/',
          name: 'sale-already-happened',
          component: Step3SaleAlreadyHappened,
          pathToRegexpOptions: { strict: true },
          meta: {
            title: 'Has the sale already happened?',
            middleware: [
              checkStep1,
              checkStep2
            ]
          }
        },
        // STEP 4
        {
          path: '/will-this-be-sold/',
          name: 'will-this-be-sold',
          component: Step4WillThisBeSold,
          pathToRegexpOptions: { strict: true },
          meta: {
            title: 'Will this solution be sold:',
            middleware: [
              checkStep1,
              checkStep2,
              checkStep3
            ]
          }
        },
        // STEP 5
        {
          path: '/customer-info/',
          name: 'customer-info',
          component: Step5CustomerInfo,
          pathToRegexpOptions: { strict: true },
          meta: {
            title: 'Customer Info',
            middleware: [
              checkCustomerInfoAttrs,
              checkStep1,
              checkStep2,
              checkStep3,
              checkStep4
            ]
          }
        },
        // CONFIRM
        {
          path: '/lets-confirm/',
          name: 'lets-confirm',
          component: Step6LetsConfirm,
          pathToRegexpOptions: { strict: true },
          meta: {
            title: 'Lets Confirm',
            middleware: [
              checkCurrentBrand,
              checkStep1,
              checkStep2,
              checkStep3,
              checkStep4,
              checkStep5
            ]
          }
        },
        // ALMOST DONE
        {
          path: '/almost-done/',
          name: 'almost-done',
          component: Step7AlmostDone,
          pathToRegexpOptions: { strict: true },
          meta: {
            title: 'Almost Done',
            middleware: [
              checkStep1,
              checkStep6
            ]
          }
        },
        // END STEPS
        {
          path: '/bonus-schedule/',
          name: 'bonus-schedule',
          component: BonusSchedule,
          pathToRegexpOptions: { strict: true },
          meta: {
            title: 'Bonus Schedule'
          }
        },
        {
          path: '/training-guide/',
          name: 'training-guide',
          component: TrainingGuide,
          pathToRegexpOptions: { strict: true },
          meta: {
            title: 'ProLead Training Guide'
          }
        },
        {
          path: '/gm-faq/',
          name: 'gm-faqs',
          component: GMFAQs,
          pathToRegexpOptions: { strict: false },
          meta: {
            title: 'GM FAQs'
          }
        },
        {
          path: '/faq/',
          name: 'faqs',
          component: FAQs,
          pathToRegexpOptions: { strict: false },
          meta: {
            title: 'FAQs'
          }
        },
        {
          path: '/promach-solutions/',
          name: 'promach-solutions',
          component: ProMachSolutions,
          pathToRegexpOptions: { strict: true },
          meta: {
            title: 'ProMach Solutions'
          }
        },
        {
          path: '/maintenance/',
          name: 'maintenance',
          component: Maintenance,
          pathToRegexpOptions: { strict: true },
          meta: {
            title: 'Maintenance Page'
          }
        },
        // {
        //   path: '/add-bookmark/',
        //   name: 'add-bookmark',
        //   component: AddBookmark,
        //   pathToRegexpOptions: { strict: true },
        //   meta: {
        //     title: 'Add Bookmark/Shortcut'
        //   }
        // },
        {
          path: '/:pathMatch(.*)*',
          component: PageNotFound
        }
  ]
})

const pathsToCheck = [ '/allpax', '/axon', 'bartelt', '/benchmark', '/brenton', '/cld', '/codetech', '/dekka', '/edson', '/epi', '/federal', '/ferlo', '/fltecnics', '/fogg', '/greydon', '/idtechnology', '/kleenline', '/matrix', '/modern', '/njm', '/orion', '/ossid', '/pelabellers', '/pace', '/pacific', '/packlab', '/packwest', '/panther', '/pharmaworks', '/quest', '/reepack', '/rennco', '/robertspolypro', '/serpa', '/shuttleworth', '/southern', '/statcodsi', '/statera', '/stock', '/techniblend', '/texwrap', '/wexxar', '/wls', '/zalkin', '/zarpac', '/zpi', '/leads', '/users' ];

router.beforeEach((to, from, next) => {
  if (pathsToCheck.some(path => to.path.includes(path))) {
    const newUrl = `${import.meta.env.VITE_ADMIN_DOMAIN}${to.fullPath}`;
    window.location.href = newUrl;
  } else if (to.path.includes('/cms/training')) {
    next('/training-guide/');
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  const middleware = to.meta.middleware;
  const store = useStore();

  if (!middleware || middleware.length === 0) {
    return next();
  }

  const context = { to, from, next, store };
  const nextMiddleware = (index = 0) => {
    const mw = middleware[index];
    if (!mw) {
      return next();
    }
    mw({ ...context, nextmw: () => nextMiddleware(index + 1) });
  };

  nextMiddleware();
});

router.onError((error, to) => {
  if (
    error.message.includes('Failed to fetch dynamically imported module') ||
    error.message.includes('Importing a module script failed')
  ) {
    const store = useStore();
    const targetUrl = to?.fullPath ? to.fullPath : window.location.href
    store.triggerUpdateAppVersionModal('We added new features and there is a new version of ProLead available.<br /> <br />Please click this button to update to the new version.<br /><br />')
    store.setRouteErrorTo(targetUrl)
  }
});

export default router
