<!-- components/UpdateAppVersionModal.vue -->
<template>
  <v-dialog v-model="isOpen" persistent max-width="500px" >
    <v-card>
<!--      <v-card-title class="headline">Error</v-card-title>-->
      <v-card-text v-html="message">
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <button class="custom-btn" @click="closeModal">Update to new ProLead version</button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
  import { computed } from 'vue'
  import { useStore } from '../store/store.js'

  const store = useStore()

  const isOpen = computed({
    get: () => store.showUpdateAppVersionModal,
    set: (val) => {
      if (!val) {
        store.closeUpdateAppVersionModal()
      }
    }
  })

  const message = computed(() => store.updateAppVersionModalMessage)

  const closeModal = async () => {
    const url = store.routeErrorTo || window.location.href
    await handleHardReload(url)

    store.closeUpdateAppVersionModal()
  }

  async function handleHardReload(url) {
    // Reload stylesheets
    document.querySelectorAll('link[rel="stylesheet"]').forEach((link) => {
      const newLink = link.cloneNode()
      newLink.href = link.href
      link.remove()
      document.head.appendChild(newLink)
    })

    // Reload icons
    document.querySelectorAll('link[rel="icon"]').forEach((icon) => {
      const newIcon = icon.cloneNode()
      newIcon.href = icon.href
      icon.remove()
      document.head.appendChild(newIcon)
    })

    // Fetch the URL with no-cache headers
    await fetch(url, {
      headers: {
        Pragma: 'no-cache',
        Expires: '-1',
        'Cache-Control': 'no-cache',
      },
    })

    // Reload the page
    window.location.href = url
    window.location.reload()
  }
</script>

<style>
.custom-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 12px 2px 12px;
  margin-bottom: 6px;
  width: fit-content;
  height: 36px;
  background-color: #009A76;
  color: white;
  border-radius: 50px;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  transition: opacity 0.2s ease-in-out;
}

.custom-btn:hover {
  opacity: .92;
}
</style>